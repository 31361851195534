import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            es: {
                translation: {
                    subscriptionPlans: {
                        "free": "Plan gratuito",
                        "trimestral": "Plan trimestral",
                        "mensual": "Plan mensual",
                        "digital": "Mential Care",
                        "mensual - digital": "Plan mensual digital",
                        "Programa de salud - Mensual": "Plan mensual",
                        "Programa de salud - Trimestral": "Plan trimestral",
                        "mensual - prueba primera semana": "Plan mensual - prueba primera semana",
                        "prueba primer mes": "Plan mensual - prueba primer mes",
                        "mensual - prueba primer mes": "Plan mensual - prueba primer mes",
                        "mensual - prueba primer mes gratis": "Plan mensual - prueba primer mes gratis",
                    },
                    fhirSystemCodes: {
                        "video-therapy-follow-up": "Cuestionario de seguimiento de videconsulta",
                        "video therapy follow up": "Cuestionario de seguimiento de videconsulta",
                        "video-therapy": "Videoconsulta",
                        "status": {
                            "requested": "Solicitado/Pendiente",
                        }

                    },
                    programNames: {
                        "mential-anxiety": "Programa de ansiedad",
                        "mential-depression": "Programa de estado de ánimo",
                        "mential-anxiety-depression": "Programa de estado de ánimo y depresión",
                        "mential-menopause": "Programa de menopausia",
                        "mential-pregnancy": "Programa de embarazo",
                        "mential-pregnancy-postpartum": "Programa de embarazo y postparto",
                        "mential-mourning": "Programa de duelo",
                        "390808007": "Programa de salud mental"


                    },
                    resources: {
                        "Videoconsulta": "Videoconsulta",
                        "women-onboarding-assessment": "Primer diagnóstico",
                        "women-onboarding": "Primer diagnóstico",
                        "history-medical-information-assessment": "Historial médico",
                        "behavioral-activation-for-depression-scale": "Cuestionario de activación conductual para la depresión",
                        "diafragmatic-breath": "Respiración diafragmática",
                        "progressive-muscle-relaxation": "Relajación muscular progresiva",
                        "mindfullness-pill": "Atención plena",
                        "emotional-identification": "Identificación emocional",
                        "sleeping-habits-test": "Test de hábitos de sueño",
                        "conductual-activation-plan": "Plan de activación conductual",
                        "spec-alternative-thought": "Pensamientos alternativos",
                        "feedback-therapy-patient": "Feedback de la terapia",
                        "feedback-therapy-practicioner": "Feedback de la terapia especialista",
                        "video-therapy": "Videoconsulta",
                        "abrazar-emociones-desagradables": "Identificación emocional. Abrazar emociones desagradables",
                        "autovaloracion-areas-vitales": "Autovaloración: áreas vitales",
                        "emociones-pasadas": "Etiquetado emocional: Emociones pasadas",
                        "autovaloracion-discurso-interno": "Autovaloración: discurso interno",
                        "autonomia-logros": "Autonomía: logros",
                        "mood-tracker": "Registro emocional",
                        "onboarding-etapa-01": "Etapa 1",
                        "onboarding-therapy-program": "Bienvenida a mential",
                        "REC-conciencia-emocional-basicas": "Conciencia emocional: emociones básicas",
                        "REC-autovaloracion-autocriticar": "Autovaloración: autocrítica",
                        "exercise-revision": "Revisión de ejercicio completado",
                        "REC-menopausia-higiene-sueño": "Higiene del sueño en menopausia",
                        "REC-DPN-utilidad": "Desafiar pensamientos negativos: Utilidad de mis pensamientos",
                        "REC-modelo-PERMA": "Modelo PERMA del Bienestar",
                        "weekly-followup": "Seguimiento semanal",
                        "REC-psicoeducativo-hipotiroidismo": "Psicoeducativo hipotiroidismo",
                        "REC-autocuidado-actividades-cuidan-autoestima": "Actividades que cuidan la autoestima",
                        "REC-hobbies-responsabilidades": "Hobbies y responsabilidades",
                        "REC-menopausia-evitacion-situaciones": "Evitación de situaciones en menopausia",
                        "REC-que-haria-diferente": "Qué harías diferente",
                        "REC-objetivos-conseguidos": "Objetivos conseguidos",
                        "REC-cuestionario-autoestima": "Cuestionario ¿Como me veo a mí misma?",
                        "REC-autorrespeto-conocer-derechos": "Autorrespeto: Conocer derechos asertivos",
                        "REC-primera-consulta-objetivos": "Primera consulta - objetivos",
                        "REC-registro-ABCDE": "Discutir creencias. Registro ABCDE",
                        "REC-creencias-conclusiones-pertinentes": "Discutir creencias. Extraer las conclusiones pertinentes",
                        "REC-organizar-tareas": "Priorización y organización de tareas ",
                        "REC-psicoeducacion-efecto-pensamientos": "Psicoeducación: el efecto de los pensamientos",
                        "REC-duelo-cartas": "Cartas en duelo",
                        "REC-duelo-ventilacion-emocional": "Ventilación emocional",
                        "REC-psicoeducacion-ansiedad": "Psicoeducación qué es la ansiedad",
                        "REC-autoconocimiento-fortalezas": "Autonocimiento: fortalezas",
                        "REC-duelo-rituales": "Rituales de despedida",
                        "REC-MF-Tecnica7": "Mindfulness: Última semana",
                        "REC-onboarding-general": "Cuestionario onboarding general",
                        "REC-duelo-retomar-rutinas": "Restablecimiento de rutinas en duelo",
                        "REC-MF-Tecnica2": "Mindfulness: Segunda semana",
                        "FINALIZACION-EVALUACION": "Finalización evaluación diagnóstica",
                        "REC-metafora-dos-montañas": "Metafora de las dos montañas",
                        "REC-mindful-eating": "Alimentación consciente",
                        "REC-MF-Tecnica1": "Mindfulness: Primera semana",
                        "REC-assertive-communication-pill": "Píldora de comunicación asertiva",
                        "REC-duelo-anclaje": "Anclaje al presente",
                        "REC-duelo-reconocimiento-emocional": "Reconocimiento emocional durante el duelo",
                        "REC-APE-agradables": "Actividades de protección emocional agradables",
                        "REC-duelo-valores": "Valores y prioridades tras la pérdida",
                        "REC-menopausia-psicoeducacion": "Qué es la menopausia",
                        "REC-lenguaje-compasivo": "Lenguaje compasivo",
                        "REC-autoconocimiento-valores": "Autoconocimiento: valores",
                        "REC-autorrespeto-limites": "Autorrespeto: límites",
                        "REC-duelo-pensamientos": "Identificación de pensamientos en duelo",
                        "REC-hipotiroidismo-cuestionario": "Cuestionario inicial hipotiroidismo",
                        "REC-DPN-control": "Desafiar pensamientos negativos: Control de la situación",
                        "REC-tracker-respiracion": "Seguimiento respiración diafragmática",
                        "REC-asertividad-criticas": "Habilidades de comunicación asertiva. Hacer frente a la crítica",
                        "REC-registro-conductas-evitacion": "Conductas de evitación",
                        "REC-APE-vitalizantes": "Actividades de proteccion emocional vitalizantes",
                        "REC-conducta-motivada": "La conducta motivada",
                        "REC-mential-etapa3": "Etapa 3. Aplicación de distintas herramientas en áreas vitales",
                        "REC-que-es-el-duelo": "Qué es el duelo y cuál es su función",
                        "REC-mindfulness-informal-tareas-cotidianas": "Mindfulness práctica informal en tareas cotidianas",
                        "REC-solucion-problemas-emocional": "Solución de problemas basada en la emoción",
                        "REC-preocupaciones-reales-hipoteticas": "Preocupaciones reales e hipotéticas",
                        "REC-identificar-emociones-desagradables": "Identificar emociones desagradables",
                        "REC-seguimiento-sueño": "Registro diario de sueño",
                        "REC-etiquetado-emocional-presentes": "Etiquetado emocional: Emociones presentes",
                        "REC-autovaloracion-autocritica": "Autovaloración: autocritica",
                        "REC-autoconcepto-metas-proposito": "Autoconcepto: metas y propósitos",
                        "REC-nuevos-habitos-rutinas": "Nuevos hábitos y rutinas ",
                        "REC-aceptacion-perdidas": "Aceptacion y pérdidas",
                        "REC-DPN-loquemedigo": "Desafiar pensamientos negativos: Lo que me digo a mí misma",
                        "REC-autoconcepto-nuestras-acciones": "Autoconcepto: nuestras acciones",
                        "REC-discutir-creencias": "Discutir creencias. Reescribir pensamientos y creencias.",
                        "REC-visualizacion-afrontamiento": "Visualización del afrontamiento",
                        "REC-enraizamiento": "Enraizamiento o RETEEVO",
                        "REC-que-cambiar-que-no": "Qué cambiar y qué no",
                        "REC-orientacion-formulacion-problemas": "Orientación y formulación del problema",
                        "REC-gratitud-carta-agradecimiento": "Carta de gratitud",
                        "REC-fomentar-actividad-fisica": "Fomentar la actividad física",
                        "REC-duelo-cambios-experimentados": "Que me esta ocurriendo en el duelo",
                        "BIENVENIDA-DIAGNOSTICO": "bienvenida evaluacion diagnostica",
                        "REC-conectar-humor": "Conectar con el humor",
                        "REC-menopausia-seguimiento-sofocos": "Seguimiento diario de sofocos",
                        "REC-duelo-fortalezas": "Reinvencion de fortalezas y habilidades",
                        "REC-parada-pensamiento": "Técnica de parada de pensamiento",
                        "REC-MF-Tecnica6": "Mindfulness: Sexta semana",
                        "REC-autoconcepto-comonoshemosconstruido": "Cómo nos hemos construido",
                        "REC-visualizacion-calma": "Visualización: calma a través de los sentidos",
                        "REC-MF-Tecnica4": "Mindfulness: Cuarta semana",
                        "REC-menopausia-habitos-saludables": "Habitos saludables en menopausia",
                        "REC-solucion-problemas-activo": "Afrontamiento activo de problemas",
                        "REC-atribucion-interna": "La atribución interna",
                        "REC-menopausia-cambios": "síntomas y cambios en menopausia",
                        "REC-mential-etapa2": "Etapa 2. Herramientas para reducir la sintomatología emocional",
                        "REC-asertividad-hablar-desde-el-yo": "Habilidades asertivas. Hablar desde el yo.",
                        "REC-duelo-apoyos": "Mi red de apoyo en estos momentos",
                        "REC-registro-reacciones-fisiologicas": "Registro reacciones fisiológicas",
                        "REC-optimismo-inteligente": "Optimismo inteligente",
                        "REC-mential-etapa4": "Etapa 4. Planificación a futuro y compromiso con el cambio.",
                        "REC-linea-vida-agradables": "Línea de vida emociones agradables",
                        "REC-MF-Tecnica5": "Mindfulness: Quinta semana",
                        "REC-cuestionario-afrontamiento": "Cuestionario de comunicación y afrontamiento de problemas",
                        "REC-menopausia-despertares-nocturnos": "Despertares nocturnos durante la menopausia",
                        "REC-autoconocimiento-habilidades-adquiridas": "Autonocimiento: habilidades adquiridas",
                        "REC-MF-Tecnica3": "Mindfulness: Tercera semana",
                        "REC-segmentar-tareas": "Segmentar tareas",
                        "REC-linea-vida-desagradables": "Línea de vida de emociones desagradables",
                        "REC-DPN-evidencia": "Desafiar pensamientos negativos: Busqueda evidencia",
                        "REC-mindfulness-informal-actividades-agradables": "Mindfulness práctica informal actividades agradables",
                        "REC-asertividad-realizar-peticiones": "Habilidades asertivas: realizar peticiones",
                        "REC-DPN-ysifueracierto": "Desafiar pensamientos negativos: Y si fuera cierto",
                        "REC-manejar-culpa": "Manejo de la culpa ",
                        "REC-FAS-ASSESSMENT": "Cuestionario FAS",
                        "REC-sesgos-cognitivos": "Sesgos cognitivos",
                        "REC-diario-gratitud": "Diario de gratitud",
                        "REC-duelo-frases": "Frases de despedida",
                        "REC-soledad-aislamiento": "Psicoeducacion soledad y aislamiento",
                        "REC-seguimiento-asertividad": "Habilidades asertivas. Seguimiento semanal"
                    },
                    decisionRuleConfig: {
                        programObjectivesAssignment: "Asignación de objetivos del programa",
                        programAssignment: "Asignación de programa",
                        "create-task-medical-history-assessment": "Crear tarea historial médico",
                        "evaluateDiagnosisType": "Evaluar tipo de diagnóstico",
                        "assign-program-objectives": "Asignar objetivos del programa",
                        "propose-program": "Proponer programa",
                        "create-task": "Crear tarea",
                        "create-condition": "Crear condición",
                    },
                    tasks: {
                        statuses: {
                            "pending": "Pendiente",
                            "completed": "Completado",
                            "in-progress": "En progreso",
                            "failed": "Fallido",
                            "cancelled": "Cancelado",
                            "fulfilled": "Completado",
                            "entered-in-error": "Error",
                            "requested": "Pendiente",
                            "proposed": "Propuesto",
                            "ready": "Listo para realizar",
                            "draft": "Borrador",
                        }
                    },
                    adminPanel: {
                        severity: "Severidad",
                        categoriesDisplay: "Categorias",
                        codeDisplay: "Código",
                        id: "Id",
                        type: "Tipo",
                        name: "Nombre",
                        description: "Descripción",
                        display: "Forma de mostrar",
                        deleted: "Eliminado",
                        resourcename: "Nombre del recurso",
                        botId: "Id del bot",
                        measurableValues: "Valores medibles",
                        isRemissible: "Es remisible",
                        measurableThrough: "Medible a través de",
                        item: "Item",
                    },
                    views: {
                        "patientProgramProfile": "Perfil del paciente",
                        patientProgramNextSessions: "Citas",
                        goalsTitle: "Objetivos del programa",
                        noGoals: "No hay objetivos definidos en el programa",
                        asignNewObjectives: "Asignar nuevos objetivos",
                        patientProgramJourney: "Historial",
                        tasks: {
                            title: "Tareas pendientes",
                            taskCardSource: "Origen de la tarea",
                            taskCardTask: "Tarea",
                            taskCardStatus: "Estado",
                            taskCardQuestionnaire: "Cuestionario"
                        },
                        chat: {
                            wantToTalk: "¿Quieres hablar?",
                        }
                    },
                    profile: {
                        maritalStatus: 'Estado civil',
                        employmentSituation: 'Situación laboral',
                        familyHasChildren: '¿Tiene hijos?',
                        hasChildren: '¿Tiene hijos?',
                        birthyear: 'Año de nacimiento',
                        birthdate: 'Fecha de nacimiento',
                        year: 'Año de nacimiento',
                        substance: '¿Consume alguna sustancia?',
                        substanceUse: '¿Consume alguna sustancia?',
                        firstName: "Nombre",
                        lastName: "Apellido",
                        fullname: "Nombre completo",
                        postalCode: "Código postal",
                        "therapySessionAvailability": "Disponibilidad",
                    },
                    "morning, afternoon": "Todo el dia",
                    "morning": "Mañana",
                    "afternoon": "Tarde",
                    fhir: {
                        "goalStatuses": {
                            "in-progress": "En progreso",
                            "on-hold": "En espera",
                            "cancelled": "Cancelado",
                            "completed": "Completado",
                            "entered-in-error": "Error",
                            "rejected": "Rechazado",
                            "proposed": "Propuesto",
                            "accepted": "Aceptado",
                            "planned": "Planificado",
                            "requested": "Pendiente",
                            "draft": "Borrador",
                            "suspended": "Suspendido",
                            "active": "Activo",
                        },
                        clinicalStatuses: {
                            "active": "Activa",
                            "relapse": "Recaída",
                            "remission": "Remitida",
                            "resolved": "Resuelta",
                            "inactive": "Inactiva",
                            "detected": "Detectado",
                            "confirmed": "Confirmada",
                            "refuted": "Refutada",
                            "entered-in-error": "Error",
                            "unknown": "Desconocida",
                        },
                        severity: {
                            "MILD": "Leve",
                            "MODERATE": "Moderada",
                            "SEVERE": "Severa",
                            "INFO": "Informativa",

                        }


                },
                fhirCodes: {
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    'another-traumatic-experience': 'Ha sufrido otra experiencia traumática no registrada',
                    'anxiety-disorders-diagnosis': 'Trastorno de ansiedad',
                    'any-condition-affecting-psychological-state': 'Tiene alguna condición que afecte a su estado psicológico',
                    'any-medical-condition': 'Tiene un diagnostico o condiciones médicas previas',
                    'cognitive-disorders-diagnosis': 'Trastorno cognitivos',
                    'consume-medication': 'Consume medicamentos',
                    'currently-in-treatment': 'Actualmente en tratamiento psicológico',
                    'diseases': 'Enfermedades',
                    'disruptive-impulse-control-and-conduct-disorders-diagnosis': 'Trastorno de la conducta y del control de los impulsos perturbadores',
                    'dissociative-disorders-diagnosis': 'Trastorno disociativos',
                    'divorce': 'Esta divorcido',
                    'employed': 'Esta trabajando',
                    'family-with-mental-problems': 'Tiene familia con problemas mentales',
                    'feeding-and-eating-disorders-diagnosis': 'Trastorno de la alimentación',
                    'gender-dysphoria-diagnosis': 'Diagnostico de disforia de género',
                    'has-children-between-five-and-twelve': 'Tiene hijos de entre cinco y doce años',
                    'has-children-over-twenty': 'Tiene hijos mayores de veinte años',
                    'has-children-under-five': 'Tiene hijos menores de cinco años',
                    'infectious-disease': 'Enfermedades infecciosas',
                    'interest-articles': 'Interes en artículos de interés',
                    'interest-couple-life': 'Interes en',
                    'interest-feeling-better': 'Interes en',
                    'interest-life-quality': 'Interes en mejorar su calidad de vida',
                    'interest-look-for-job': 'Interés en buscar trabajo',
                    'interest-my-family': 'Interesa en su',
                    'interest-nutrition': 'Interes en',
                    'interest-physical-activity': 'Interes en',
                    'interest-relaxing-execices': 'Interes en ejercicios de relajación',
                    'interest-self-knowledge': 'Interés en recursos de autoconocimiento',
                    'interest-share-with-other-women': 'Interes en compartir experiencias con otras mujeres',
                    'interest-therapy': 'Interes en terapia',
                    'lives-with-partner': 'Vive con su pareja',
                    'loss-of-friends-or-relatives': 'Ha sufrido pérdida de amigos o familiares',
                    'loss-of-work': 'Ha sufrido pérdida de trabajo',
                    'mental-disorders-due-to-medical-condition-not-elsewhere-classified diagnosis': 'Trastornos mentales debidos a afecciones médicas no clasificadas en otro apartado diagnóstico',
                    'mood-disorders-diagnosis': 'Trastorno del estado de ánimo',
                    'neurodevelopmental-disorders diganosis': 'Trastorno del neurodesarrollo diganosis',
                    'no-children-or-similar': 'No tiene hijos',
                    'no-lives-with-partner': 'No vive con su pareja',
                    'obsessive-compulsive-and-related-disorders-diagnosis': 'Trastorno obsesivo-compulsivos y afines',
                    'other-disadvantages': 'Otros inconvenientes',
                    'paraphilic-disorders-diagnosis': 'Trastorno parafílico',
                    'personal-worries-breakup-separation-divorce': 'Preocupaciones personales ruptura separación divorcio',
                    'personal-worries-children-health': 'Preocupado por la salud infantil',
                    'personal-worries-children-raising': 'Preocupado por lacrianza de los hijos',
                    'personal-worries-grief-loss': 'Preocupado por un duelo pérdida',
                    'personal-worries-nutrition': 'Preocupado por su nutrición',
                    'personal-worries-partner-health': 'Preocupado por la salud de la pareja',
                    'personal-worries-personal-health': 'Preocupado su salud personal',
                    'personal-worries-physical-appearance': 'Preocupado por su aspecto físico',
                    'personal-worries-pregnancy': 'Preocupado por su embarazo',
                    'personal-worries-social-relations': 'Preocupado por las relaciones sociales',
                    'personal-worries-wellbeing': 'Preocupado por su bienestar',
                    'personal-worries-work-environment': 'Preocupado por su entorno laboral',
                    'personality-disorders-diagnosis': 'Trastorno de personalidad',
                    'previous-diagnosis-anxiety-disorders': 'Diagnóstico previo de trastornos de ansiedad',
                    'previous-diagnosis-cognitive-disorders': 'Diagnóstico previo trastornos cognitivos',
                    'previous-diagnosis-disruptive-impulse-control-and-conduct-disorders': 'Diagnóstico previo de trastornos del control de los impulsos y de la conducta',
                    'previous-diagnosis-dissociative-disorders': 'Diagnóstico previo trastornos disociativos',
                    'previous-diagnosis-feeding-and-eating-disorders': 'Diagnóstico previo de trastornos de la alimentación',
                    'previous-diagnosis-gender-dysphoria': 'Diagnóstico previo de disforia de género',
                    'previous-diagnosis-mental-disorders-due-to-medical-condition-not-elsewhere-classified': 'Diagnóstico previo trastornos mentales debidos a afección médica no clasificada en otro apartado',
                    'previous-diagnosis-mood-disorders': 'Diagnóstico previo de trastornos del estado de ánimo',
                    'previous-diagnosis-neurodevelopmental-disorders': 'Diagnóstico previo trastornos del neurodesarrollo',
                    'previous-diagnosis-obsessive-compulsive-and-related-disorders': 'Diagnóstico previo de trastornos obsesivo-compulsivos y afines',
                    'previous-diagnosis-paraphilic-disorders': 'Diagnóstico previo trastornos parafílicos',
                    'previous-diagnosis-personality-disorders': 'Diagnóstico previo de trastornos de la personalidad',
                    'previous-diagnosis-schizophrenia-spectrum-disorders': 'Diagnóstico previo trastornos del espectro esquizofrénico',
                    'previous-diagnosis-sexual-dysfunctions': 'Diagnóstico previo disfunciones sexuales',
                    'previous-diagnosis-sleep-wake-disorders': 'Diagnóstico previo trastornos del sueño vigilia',
                    'previous-diagnosis-somatic-symptom-and-related-disorders': 'Diagnostico previo de síntoma somático y trastornos relacionados',
                    'previous-diagnosis-substance-consumption-related-disorders': 'Diagnóstico previo de trastornos relacionados con el consumo de sustancias',
                    'previous-diagnosis-trauma-and-stressor-related-disorders': 'Diagnóstico previo de trauma y trastornos relacionados con el estrés',
                    'previously-any-surgery-or-medical-procedure': 'Asistió a cirugía o procedimiento médico previo',
                    'previously-in-mental-health-hospital': 'Anteriormente estuvo en un hospital psiquiátrico',
                    'schizophrenia-spectrum-disorders-diagnosis': 'Trastornos del espectro esquizofrénico',
                    'self-employed': 'Trabaja como autónomo',
                    'sexual-dysfunctions-diagnosis': 'Trastorno de disfunciones sexuales',
                    'single-person': 'Se encuentra sin pareja',
                    'sleep-wake-disorders-diagnosis': 'Trastorno del sueño y la vigilia',
                    'somatic-symptom-and-related-disorders-diagnosis': 'Síntomas somáticos y trastornos relacionados',
                    'student': 'Estudiante',
                    'substance-consumtion-alcohol': 'Consumo de alcohol',
                    'substance-consumtion-drugs': 'Consumo de drogas',
                    'substance-consumtion-tobacco': 'Consumo tabaco',
                    'trauma-and-stressor-related-disorders-diagnosis': 'Traumas y trastornos relacionados con el estrés',
                    'unemployed': 'Se encuentra en paro',
                    'violence-or-abuse': 'Violencia o abusos',
                    'cognitive-behavioral-therapy': 'Terapia cognitivo-conductual',
                    'values-based-acceptance-commitment-therapy': 'Terapia de aceptación y compromiso basada en valores',
                    'acceptance-commitment-therapy-based-values': 'Valores basados en la terapia de compromiso y aceptación',
                    'behavioral': 'Comportamiento',
                    'depression-synthoms': 'Síntomas de depresión',
                    'physical': 'Físico',
                    'medical-synthoms': 'Síntomas médicos',
                    'mental-synthoms': 'Síntomas mentales',
                    'somatization-diagnosis': 'Diagnóstico de somatización',
                    'mental-diagnosis': 'Diagnóstico salud mental',
                    'mental-diagnosis-type': 'Tipo de diagnóstico mental',
                    'anxiety-diagnosis': 'Diagnóstico de ansiedad',
                    'mental-score': 'Puntuación mental',
                    'depression-diagnosis': 'Trastorno de depresión',
                    'events-and-worries': 'Acontecimientos y preocupaciones',
                    'daily-sleeping-hours': 'Horas diarias de sueño',
                    'weekly-physical-activity': 'Actividad física semanal',
                    'problems-sleeping-last-month': 'Problemas para dormir el último mes',
                    'nausea-upset-stomach-synthom-likert-scale': 'Sintoma de náuseas malestar estomacal; likert scale',
                    'heart-pains-chest-synthom-likert-scale': 'Síntoma de dolor en el pecho; escala likert',
                    'no-interest-things-synthom-likert-scale': 'Sintoma de estar sin interés cosas; escala likert',
                    'terror-attacks-panic-synthom-likert-scale': 'Sintoma de ataques de pánico; escala likert',
                    'somatization-bsi18-score': 'Puntuación bsi18 de somatización',
                    'nervousness-trembling-synthom-likert-scale': 'Sintoma de nerviosismo tembloroso escala likert',
                    'total-degree': 'Titulación total',
                    'breath-shortness-synthom-likert-scale': 'Sintoma de dificultad respiratoria; escala likert',
                    'sad-feelings-synthom-likert-scale': 'Sintoma de sentimientos tristes; escala likert',
                    'feeling-hopeless-about-future-synthom-likert-scale': 'Síntoma de sentirse desesperanzado sobre el futuro; escala likert',
                    'numbness-tingling-body-parts-synthom-likert-scale': 'Síntoma de entumecimiento hormigueo partes del cuerpo; escala likert',
                    'anxiety-degree': 'Grado de ansiedad',
                    'sudden-scares-no-reason-synthom-likert-scale': 'Sintoma de sustos repentinos sin motivo escala: likert',
                    'restless-no-sit-still-synthom-likert-scale': 'Sintoma de inquietud no sentarse quieto: escala likert',

                    'sleeping-degree': 'Grado de calidad sueño',
                    'feeling-weak-body-parts-synthom-likert-scale': 'Sintoma de sensación de debilidad partes del cuerpo: escala likert',

                    'feeling-worthless-synthom-likert-scale': 'Sintoma de sentirse inútil: escala likert',
                    'thoughts-ending-life-synthom-likert-scale': 'Sintoma de pensamientos que ponen fin a la vida sintoma: escala likert',
                    'feeling-fearful-synthom-likert-scale': 'Sintoma de sentir miedo: escala likert',
                    'somatization-degree': 'Grado de somatización',
                    'depression-degree': 'Grado de depresión',
                    'feeling-tense-upset-synthom-likert-scale': 'Sintoma de sentirse tenso disgustado; escala likert',
                    'personal-worries': 'Preocupaciones personales',
                    'feeling-alone-synthom-likert-scale': 'Sintoma de sentirse solo; escala likert',

                    'anxiety-bsi18-score': 'Ansiedad puntuación bsi18',
                    'fainting-dizziness-synthom-likert-scale': 'Sintoma de desmayo mareo escala likert',
                    'total-bsi18-score': 'Puntuación total bsi18',
                    'sleeping-quality-overall': 'Calidad general del sueño',

                    'depression-bsi18-score': 'Depresión puntuación bsi18',
                    'substance-consumption': 'Consumo sustancias',
                    'sociodemographic': 'Sociodemográfico',
                    'acquire-new-emotional-management-skills-interest': 'Interés en adquirir nuevas habilidades de gestión emocional',
                    'buen-desayuno': 'Buen desayuno',
                    'cognitive-behavioral-techniques-resource-interest': 'Interés por las técnicas cognitivo-conductuales',
                    'currently-in-therapy': 'Actualmente en terapia',
                    'daily-feedback-status': 'Estado diario de los comentarios',
                    'digital-apps-for-personal-growth-resource-interest': 'Interés en aplicaciones digitales para el crecimiento personal',
                    'discover-my-potencial-interest': 'Interés en descubrir su potencial',
                    'free-chat-with-a-therapist-resource-interest': 'Interés en chat gratuito con un terapeuta',
                    'improve-my-confidence-and-self-esteem-interest': 'Interés mejorar mi confianza e interés por la autoestima',
                    'improve-my-mood-interest': 'Interés en',
                    'improve-my-quality-of-life': 'Interés en',
                    'improve-my-relationship-interest': 'Interés en mejorar mi interés por las relaciones',
                    'improve-my-relationships-interest': 'Interés en mejorar mi interés por las relaciones',
                    'improve-work-life-balance-interest': 'Interés en mejorar la conciliación de la vida laboral y familiar',
                    'introspection-and-self-knowledge-therapy-resource-interest': 'Interés en técnicas de introspección y autoconocimiento terapia',
                    'malo-desayuno': 'Malo desayuno',
                    'never-received-therapy': 'Nunca ha recibido terapia',
                    'overcome-a-loss-interest': 'Interés en superar de pérdida',
                    'participating-in-therapy-groups-resource-interest': 'Interes en participar en grupos terapéuticos',
                    'problem-solving-and-decision-making-techniques-resource-interest': 'Interés en técnicas de resolución de problemas y toma de decisiones',
                    'process-and-heal-my-past-wounds-interest': 'Interés en procesar y curar mis heridas del pasado',
                    'quick-consultation-with-a-psychologist-resource-interest': 'Interés en tener consulta rápida con un psicólogo',
                    'readings-exercises-or-practical-activities-resource-interest': 'Interés en ejercicios de lectura o actividades prácticas',
                    'received-therapy-long-time-ago': 'Recibió terapia hace mucho tiempo',
                    'relaxation-techniques-resource-interest': 'Interés en recursos de técnicas de relajación',
                    'relieve-my-symptoms-interests': 'Interes en aliviar síntomas',
                    'se-siente-regular-diario': 'Se siente regular diariamente',
                    'self-discovery-interest': 'Interés por el autodescubrimiento',
                    'setting-specific-and-measurable-goals-resource-interest': 'Interes en establecer objetivos específicos y mensurables recurso',
                    'support-my-family interest': 'Interes en apoyar a su familiar',
                    'talk-therapy-and-reflection-resource-interest': 'Interes en terapia de conversación y recurso de reflexión',
                    'interests': 'Intereses',
                    'medical-context': 'Contexto médico',
                    'habitos': 'Hábitos',
                    'daily-felling': 'Tala diaria',
                    'medical-history': 'Historial médico',
                    'daily-feeling': 'Sensaciones diarias',
                    '310191001': 'Psicólogo general sanitario',
                    '310191001-short': 'Psicólogo',
                    'supervisor-short': 'Supervisor',
                    'yes': 'Sí',
                    'no': 'No',
                    'nothing': 'Nada',
                    'something': 'Algo',
                    'quite': 'Bastante',
                    'alot': 'Mucho',
                    'conditionLargePartOfMyLife': 'Condición que ha ocupado gran parte de mi vida',
                    'neurological-development-disorders': 'Trastornos del desarrollo neurológico',
                    'schizophrenia-spectrum': 'Espectro de la esquizofrenia',
                    'mood-disorders': 'Trastornos del estado de ánimo',
                    'anxiety-disorders': 'Trastornos de ansiedad',
                    'obsessive-compulsive-related-disorders': 'Trastornos relacionados con el trastorno obsesivo-compulsivo',
                    'trauma-and-stressor-related-disorders': 'Trastornos relacionados con el trauma y el estrés',
                    'dissociative-disorders': 'Trastornos disociativos',
                    'somatic-symptom-and-related-disorders': 'Trastornos relacionados con síntomas somáticos',
                    'feeding-and-eating-disorders': 'Trastornos de la alimentación y la alimentación',
                    'sleep-wake-disorders': 'Rastornos del sueño-vigilia',
                    'sexual-dysfunctions': 'Disfunciones sexuales',
                    'gender-dysphoria ': 'Disforia de género',
                    'disruptive-impulse-control-and-conduct-disorders': 'Trastornos de control de impulsos y de la conducta',
                    'personality-disorders': 'Trastornos de la personalidad',
                    'paraphilic-disorders ': 'Rastornos parafílicos',
                    'cognitive-disorders': 'Trastornos cognitivos',
                    'mental-disorders-due-to-medical-condition-not-elsewhere-classified': 'Trastornos mentales debido a una afección médica no clasificada en otro lugar',
                    'substance-related-and-addictive-disorders': 'Trastornos relacionados con sustancias y adictivos',
                    'supervisor': 'Supervisor',
                    "No tengo pareja": "No tengo pareja",
                    "Tengo pareja y vivimos juntos": "Tengo pareja y vivimos juntos",
                    "Tengo pareja pero no vivimos juntos": "Tengo pareja pero no vivimos juntos",
                    "No": "No",
                    "No, pero estoy embarazada": "No, pero estoy embarazada",
                    "Sí, tiene menos de 5 años": "Sí, tiene menos de 5 años",
                    "Sí, tiene entre 5 y 12 años": "Sí, tiene entre 5 y 12 años",
                    "Sí, tiene entre 12 y 20 años": "Sí, tiene entre 12 y 20 años",
                    "Sí, tiene más de 20 años": "Sí, tiene más de 20 años",
                    "No tengo trabajo": "No tengo trabajo",
                    "Trabajo por cuenta ajena": "Trabajo por cuenta ajena",
                    "Trabajo por cuenta propia": "Trabajo por cuenta propia",
                    "Estoy estudiando": "Estoy estudiando",
                    "Más de 5 días por semana": "Más de 5 días por semana",
                    "Entre 5 y 3 días por semana": "Entre 5 y 3 días por semana",
                    "Menos de 2 días por semana": "Menos de 2 días por semana",
                    "No hago nada de ejercicio": "No hago nada de ejercicio",
                    "Tabaco": "Tabaco",
                    "Alcohol": "Alcohol",
                    "Drogas": "Drogas",
                    "Ninguna": "Ninguna",
                    "Menos de 7 horas": "Menos de 7 horas",
                    "Entre 7 y 9 horas": "Entre 7 y 9 horas",
                    "Más de 9 horas": "Más de 9 horas",
                    "Ninguna vez en el último mes": "Ninguna vez en el último mes",
                    "Menos de una vez a la semana": "Menos de una vez a la semana",
                    "Una o dos veces a la semana": "Una o dos veces a la semana",
                    "Tres o más veces a la semana": "Tres o más veces a la semana",
                    "Muy buena": "Muy buena",
                    "Bastante buena": "Bastante buena",
                    "Bastante mala": "Bastante mala",
                    "Muy mala": "Muy mala",
                    "Divorcio": "Divorcio",
                    "Pérdida de trabajo": "Pérdida de trabajo",
                    "Pérdida de amigos o familiares": "Pérdida de amigos o familiares",
                    "Enfermedades": "Enfermedades",
                    "Violencia o maltrato": "Violencia o maltrato",
                    "Otra experiencia traumática": "Otra experiencia traumática",
                    "Otros inconvenientes": "Otros inconvenientes",

                    "Relaciones sociales o familiares": "Relaciones sociales o familiares",
                    "Duelo o pérdida": "Duelo o pérdida",
                    "Pareja, ruptura, separación o divorcio": "Pareja, ruptura, separación o divorcio",
                    "Embarazo": "Embarazo, postparto o la infertilidad",
                    "Problemas de salud propios o de un familiar": "Problemas de salud propios o de un familiars",
                    "Crianza de mi hijos": "Crianza de mi hijos",
                    "Mi Trabajo o ambiente laboral": "Mi Trabajo o ambiente laboral",
                    "Conducta alimentaria y/o la imagen personal": "Conducta alimentaria y/o la imagen personal",
                    "Ansiedad, estrés, miedos o ataque de pánico": "Ansiedad, estrés, miedos o ataque de pánico",
                    "Bajo estado de ánimo y/o dificultades en la regulación emocional": "Bajo estado de ánimo y/o dificultades en la regulación emocional",
                    "Control de impulsos, agresión y/o autolesiones": "Control de impulsos, agresión y/o autolesiones",
                    "Acontecimientos traumáticos": "Acontecimientos traumáticos",
                    "Cambio en las condiciones de vida": "Cambio en las condiciones de vida",
                    "Consumo de sustancias (alcohol, tabaco, otras drogas)": "Consumo de sustancias (alcohol, tabaco, otras drogas)",
                    "improve-my-relationship": "Mejorar mi vida en pareja",
                    "improve-my-mood": "Mejorar mi estado de ánimo",
                    "support-for-my-family": "Apoyo para mi familia",
                    "self-discovery": "Conocerme mejor",
                    "improve-my-confidence-and-self-esteem": "Mejorar mi confianza y autoestima",
                    "overcome-a-loss": "Superar una pérdida",
                    "improve-my-relationships": "Mejorar mis relaciones",
                    "relieve-my-symptoms": "Aliviar mis síntomas",
                    "discover-my-potential": "Descubrir mi potencial",
                    "process-and-heal-my-past-wounds": "Procesar y sanar heridas del pasado",
                    "acquire-new-emotional-management-skills": "Adquirir nuevas habilidades de gestión emocional",
                    "improve-work-life-balance": "Mejorar el equilibrio trabajo/vida personal",
                    "just-watching": "Solo miraba",
                    "talk-therapy-and-reflection": "Terapia de conversación y reflexión",
                    "cognitive-behavioral-techniques": "Técnicas cognitivas-conductuales",
                    "problem-solving-and-decision-making-techniques": "Técnicas de resolución de problemas y toma de decisiones",
                    "relaxation-techniques": "Técnicas de relajación",
                    "setting-specific-and-measurable-goals": "Estableciendo metas específicas y medibles",
                    "participating-in-therapy-groups": "Participando en grupos de terapia",
                    "readings-exercises-or-practical-activities": "Lecturas, ejercicios o actividades prácticas",
                    "introspection-and-self-knowledge-therapy": "Terapia de introspección y autoconocimiento",
                    "digital-apps-for-personal-growth": "Aplicaciones digitales de crecimiento personal",
                    "free-chat-with-a-therapist": "Chat gratis con un terapeuta",
                    "quick-consultation-with-a-psychologist": "Consulta rápida con un psicólogo",
                    "none-not-ready": "Ninguna, no estoy preparada",
                    "no-never": "No, nunca he ido a terapia",
                    "yes-long-time-ago": "Sí, hace tiempo",
                    "yes-currently": "Sí, ahora mismo voy a terapia",
                    "humoristic": "Bueno.. que más da? A mal tiempo, buena cara no?",
                    "directive": "Aquí está el plan: identificar el problema, buscar soluciones y actuar de inmediato",
                    "intelectual": "Antes de tomar una decisión, debo analizar detenidamente los pros y los contras, investigar a fondo y considerar todas las opciones",
                    "flexible": "Los planes cambian, pero eso no significa que no podamos adaptarnos y disfrutar de lo que venga",
                    "sensitive": "Ante esta situación que me afecta solo me gustaría alguien que pudiera comprenderme para que me ayudara a sobrellevarlo mejor",
                    "spontaneous": "La vida es una aventura, y a veces, las mejores experiencias ocurren cuando menos lo esperamos",
                    "developing-ones-skills": "Cuando me siento empoderada en mis capacidades y habilidades",
                    "comparing-perspectives": "Comparando distintas perspectivas",
                    "acquiring-tools": "Dándome pautas, tareas, herramientas y consejos para sobrellevarlo",
                    "emotional-support": "Escuchándome y acompañándome emocionalmente",
                    "reflecting": "Pensando y reflexionando acerca de la raíz del conflicto",
                    "seeking-immediate-solutions": "Cuando me ayudan a solucionar el malestar inmediato",
                    "understanding-conflicts-interpersonal-relationships": "Cuando me ayudan a entender mis conflictos y efecto en otras relaciones interpersonales",
                }


            }
        }
    }
})
;

export default i18n;
